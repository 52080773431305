export const AccessKey = {
    USER: "USER",
    MUTED: "MUTED",
    LANGUAGE: "LANGUAGE",
    TOKEN: "TOKEN",
    BALANCE: "BALANCE",
    CHANNEL: "CHANNEL",
    USERNAME: "USERNAME",
    USER_ID: "USER_ID",
    PHONE: "PHONE",
  } as const;